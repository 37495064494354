// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqMrmnaq3yhhe0TjceeTO1tIhIyHsKxpw",
  authDomain: "projecte-2023.firebaseapp.com",
  projectId: "projecte-2023",
  storageBucket: "projecte-2023.appspot.com",
  messagingSenderId: "343641126014",
  appId: "1:343641126014:web:8a359e71cbc10786ced42f"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
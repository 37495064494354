import { Link } from "react-router-dom";
import { useContext } from "react";
import { DataUserContext } from "../aplication/DataUserContext";
import ButtonLogout from "./ButtonLogout";
import ButtonSearchs from "./ButtonSearchs";
import ButtonLogin from "./ButtonLogin";
import ButtonRegister from "./ButtonRegister";
import ButtonTest from "./ButtonTest";

import Logo from "../assets/img/logo.jpg";
import "../styles/Header.css";

const Header = () => {
  const { userGlobal, userMail } = useContext(DataUserContext);

  return (
    <>
      <div
        className="header fixed-top "
        onClick={() => window.scroll(0, 0)}
      >
       {/*  <img
          src={Logo}
          alt=""
          width="7%"
          // height="5%"
          className="" 
        />*/}
        <div className="container d-flex justify-content-end">
          {userGlobal ? (
            <>
              {/* <ButtonTest /> */}
              <p className="me-3">¡Bienvenido, <strong className="text-success">{userMail}</strong> haz uniciado sesión!</p>
              <ButtonSearchs />
              <ButtonLogout />
              <small className="ms-5">Ver-8.2</small>
            </>
          ) : (
            <>
              <ButtonLogin/> 
              <ButtonRegister />
              <small className="ms-5">Ver-8.2</small>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Header;

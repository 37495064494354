import Modal from 'react-bootstrap/Modal';
import { Form, Button } from "react-bootstrap";
import { Navigate} from 'react-router-dom';
import { useContext, useState } from 'react';
import { DataUserContext } from "../aplication/DataUserContext";
import Message from './Message'
import firebaseApp from "../aplication/firebaseConfig";
import { getAuth, signInWithEmailAndPassword} from "firebase/auth";
const auth = getAuth(firebaseApp)


function LoginModal(props) {

  const { userGlobal, setUserMail, setValueMenu } = useContext(DataUserContext)

  async function submitLogin(e) {
    e.preventDefault();
    const mail = e.target.formEmail.value;
    const pswd = e.target.formPswd.value;
  
    await signInWithEmailAndPassword(auth, mail, pswd )
      .then((res) => {
        setUserMail(mail);
        e.target.reset();
      })
      .catch(err => {    
          alert(err.message)     
        });
    };
 
    if (userGlobal) {      
      // setValueMenu(0)  
       <Navigate to='/Trending' />
    }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Login
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <div className="container text-center">
      
      <form onSubmit={submitLogin}>
        <Form.Group controlId="formEmail">
          <div className="w-75 mx-auto mb-3">
            <Form.Control type="email" placeholder="Email Address" />
          </div>
        </Form.Group>
        <Form.Group controlId="formPswd">
          <div className="w-75 mx-auto mb-3">
            <Form.Control required type="password" placeholder="Password (mínimo 6 caracteres)" />
          </div>
        </Form.Group>
        <div className="w-25 mx-auto mb-3">
          <Button
            type="submit"
            className="form-control  text-center"
            >Login
          </Button>
        </div>
      </form>
    </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

function ButtonLogin() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button className='me-2' variant="outline-info" onClick={() => setModalShow(true)}>
        Login
      </Button>

      <LoginModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default ButtonLogin;


import { useParams } from 'react-router-dom'
import axios from "axios";
import { useContext, useEffect } from "react"
import { DataUserContext } from "../aplication/DataUserContext"
import '../styles/SelectedMovie.css'
import YouTube from "react-youtube"
import { CleaningServices } from '@mui/icons-material';

const SelectedMovie = () => {

  const { movie, img_path, playing, setPlaying, trailer, getMovie,setValueMenu } = useContext(DataUserContext);
  const {MovieId, MovieType} = useParams();
  

  useEffect(() => {
    
    getMovie(MovieId,MovieType)
    setValueMenu(5)
  }, [])


  return (
    <div className="container" >
      {movie ? (
        <div className="viewtrailer rounded"
          style={{
            backgroundImage: `url("${img_path}${movie.backdrop_path}")`,
          }} >
          <div className="row p-2">
            <div className="col">
              <div className="opacity-50 bg-black p-2 rounded mb-3 ">
                <h1 className="text-warning fw-bold">{movie.title}{movie.name}</h1>
                {movie.overview ? (
                  <p className="text-white">{movie.overview}</p>
                ) : (
                  <p className="text-white">Parece que no hay informción todavia, quizá mas adelante.</p>
                )}
              </div>
            </div>
          </div>
          <div className="row p-2" >
            <div className="col-11 col-md-3 col-lg-4 ">
              <div className=" mb-2 ">
                {movie.genres && movie.genres.map((g) => (
                  <label
                    className="bg-info rounded m-1 p-1"
                    type="text"
                    key={g.id}
                  >{g.name}
                  </label>
                ))}
              </div>
              {movie.homepage ? (<button
                className="btn btn-info"
                onClick={() => window.open(movie.homepage, '_blank')}
                type="button"
              >Web Oficial
              </button>) : (<label
                className="bg-danger p-2 rounded ms-1"
                type="text"
              >Sin Web
              </label>)}
            </div>
            <div className="col-11 col-md-8 col-lg-7 mt-sm-2 mt-2">
              {playing ? (
                <>
                  <YouTube
                    videoId={trailer.key}
                    className="reproductor container"
                    containerClassName={"youtube-container amru"}
                    opts={{
                       width: "100%",
                       height: "100%",
                      playerVars: {
                        autoplay: 0,
                        controls: 1,
                        cc_load_policy: 0,
                        fs: 0,
                        iv_load_policy: 0,
                        modestbranding: 0,
                        rel: 0,
                        showinfo: 0,
                      },
                    }}
                  />
                  <button onClick={() => setPlaying(false)} className="boton">
                    Cerrar vídeo
                  </button>
                </>
              ) : (
                <div className="container">
                  <div className="">
                    {trailer ? (
                      <button
                        className="btn btn-warning "
                        onClick={() => setPlaying(true)}
                        type="button"
                      >Abrir vídeo
                      </button>
                    ) : (
                      <>
                        <h6 className="text-danger fw-bold w-25">Lo siento, trailer no disponible</h6>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
        </>
      )}
    </div>
  )
}

export default SelectedMovie
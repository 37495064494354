//import { Navigate } from "react-router-dom";
//firebase
import firebaseApp from '../aplication/firebaseConfig'
import { getAuth, signOut} from 'firebase/auth'
const auth = getAuth (firebaseApp);

const ButtonLogout = () => {

  return (
    <>
      <button
        onClick={() => {
          signOut(auth);
          //<Navigate to='/Wellcome' />
        }}
        className="btn btn-outline-danger"
        type="button"
        >Logout
        </button>
    </>
  )
}
export default ButtonLogout
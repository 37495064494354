
import fav from "../assets/img/ninopeli3D.png";

const Favorites = () => {
  return (
    
    <>
      <div className="pageTitle">Favoritos
        <img src={fav} alt="Fav" width="10%"
        // height="5%"
        className="d-inline-block align-text-top"
        />
      </div>
    </>
  )
}

export default Favorites